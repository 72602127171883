import React from 'react';
import { Table, Button, Modal, Form, Input, Select, Popconfirm, Breadcrumb, message } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined, HomeOutlined, UserOutlined, SyncOutlined } from '@ant-design/icons';
import { getUsers, createUser, updateUser, deleteUser, getCompanies, resetUserPassword } from '../apis'; // Add resetUserPassword import
import StoreManager from '../appdata';

const { Option } = Select;

class UserManagement extends React.Component {
  state = {
    users: [],
    companies: [],
    isModalVisible: false,
    editingUser: null,
  };

  componentDidMount() {
    StoreManager.rootState.thisUserManagement = this;
    this.fetchUsers();
    this.fetchCompanies();
  }

  fetchUsers = async () => {
    try {
      const users = await getUsers();
      users.reverse();
      this.setState({ users });
      message.success('Users fetched successfully!');
    } catch (error) {
      console.error('Error fetching users:', error.message);
      message.error(`Error fetching users: ${error.message}`);
    }
  };
  
  fetchCompanies = async () => {
    try {
      const companies = await getCompanies();
      this.setState({ companies });
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  }

  generateRandomPassword = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let password = '';
    for (let i = 0; i < 8; i++) {
      password += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return password;
  }

  showModal = (user) => {
    this.setState({
      isModalVisible: true,
      editingUser: user,
    }, () => {
      const initialValues = {
        username: user ? user.username : '',
        email: user ? user.email : '',
        role: user ? user.role : 'user',
        scope: user ? user.scope[0] : null,
      };
      if (!user) {
        initialValues.password = this.generateRandomPassword();
      }
      this.formRef.current.setFieldsValue(initialValues);
    });
  };

  handleOk = async () => {
    const form = this.formRef.current;
    const values = await form.validateFields();
    values.scope = [values.scope]; // Đảm bảo scope là một mảng
  
    try {
      if (this.state.editingUser) {
        await updateUser(this.state.editingUser._id, values);
        message.success('User updated successfully!');
      } else {
        console.log("values",values)
        await createUser(values);
        message.success('User created successfully!');
      }
      this.setState({ isModalVisible: false, editingUser: null });
      this.fetchUsers();
    } catch (error) {
      console.error('Error saving user:', error.message);
      message.error(`Error saving user: ${error.message}`);
    }
  };
  

  handleCancel = () => {
    this.setState({
      isModalVisible: false,
      editingUser: null,
    });
  };

  deleteUser = async (id) => {
    try {
      await deleteUser(id);
      message.success('User deleted successfully!');
      this.fetchUsers();
    } catch (error) {
      console.error('Error deleting user:', error.message);
      message.error(`Error deleting user: ${error.message}`);
    }
  };
  

  resetPassword = async (id) => {
    try {
      const messageText = await resetUserPassword(id);
      message.success(messageText);
    } catch (error) {
      console.error('Error resetting password:', error.message);
      message.error(`Failed to reset password: ${error.message}`);
    }
  };
  

  formRef = React.createRef();

  getCompanyName = (companyId) => {
    if (!Array.isArray(this.state.companies)) return 'Unknown';
    const company = this.state.companies.find(company => company._id === companyId);
    return company ? company.company_name : 'Unknown';
  }

  render() {
    const columns = [
      { title: 'Username', dataIndex: 'username', key: 'username' },
      { title: 'Email', dataIndex: 'email', key: 'email' },
      { title: 'Role', dataIndex: 'role', key: 'role' },
      { 
        title: 'Company', 
        key: 'scope',
        render: (text, record) => {
          const companyName = record.scope && record.scope.length > 0 ? this.getCompanyName(record.scope[0]) : 'Unknown';
          return companyName;
        }
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            <Button type="primary" ghost icon={<EditOutlined />} onClick={() => this.showModal(record)} style={{ marginRight: 10 }} />
            <Popconfirm
              title="Are you sure delete this user?"
              onConfirm={() => this.deleteUser(record._id)}
              okText="Yes"
              cancelText="No"
            >
              <Button danger icon={<DeleteOutlined />} style={{ marginRight: 10 }} />
            </Popconfirm>
            <Popconfirm
              title="Are you sure reset the password for this user?"
              onConfirm={() => this.resetPassword(record._id)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="default" icon={<SyncOutlined />} />
            </Popconfirm>
          </span>
        ),
      },
    ];

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'User', value: 'user' },
    ];

    const adminRoleOptions = [
      { label: 'User', value: 'user' },
    ];

    const userRoleOptions = StoreManager.userdata.jwtDecode.role === 'superadmin' ? roleOptions : adminRoleOptions;
    let scrollY = window.innerHeight - 300

    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 14 }}>
          <Breadcrumb
            items={[
              {
                href: '',
                title: <> <HomeOutlined />
                  <span>Home</span>
                </>,
              },
              {
                title: (
                  <>
                    <UserOutlined />
                    <span>Users</span>
                  </>
                ),
              }
            ]}
          />
          <Button type="primary" icon={<PlusOutlined />} onClick={() => this.showModal(null)}>Add User</Button>
        </div>
        <Table scroll={{ y: scrollY }} bordered dataSource={this.state.users} columns={columns} rowKey="_id" />
        <Modal
          title={this.state.editingUser ? "Edit User" : "Add User"}
          visible={this.state.isModalVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Form
            layout="vertical"
            ref={this.formRef}
            initialValues={{ username: '', email: '', role: 'user', scope: null }}
          >
            <Form.Item
              label="Username"
              name="username"
              rules={[{ required: true, message: 'Please input the username!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: 'Please input the email!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Role"
              name="role"
              rules={[{ required: true, message: 'Please select the role!' }]}
            >
              <Select>
                {userRoleOptions.map(option => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {(StoreManager.userdata.jwtDecode.role === 'superadmin' || StoreManager.userdata.jwtDecode.role === 'admin') && (
              <Form.Item
                label="Company"
                name="scope"
                rules={[{ required: true, message: 'Please select the company!' }]}
              >
                <Select>
                  {this.state.companies.map(company => (
                    <Option key={company._id} value={company._id}>
                      {company.company_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {!this.state.editingUser && (
              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: 'Please input the password!' }]}
              >
                <Input />
              </Form.Item>
            )}
          </Form>
        </Modal>
      </div>
    );
  }
}

export default UserManagement;
