import React from 'react';
import { Menu, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { AppstoreOutlined, UsergroupAddOutlined, TagsOutlined, ShopOutlined, BellOutlined } from '@ant-design/icons';
import StoreManager from '../appdata';

const { Text } = Typography;
const { version } = require('../../package.json');

const AppMenu = ({ collapsed }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
      <Menu theme="light" mode="inline">
       
        {
          StoreManager.userdata.jwtDecode?.role === "superadmin" ? (
            <Menu.Item key="1" icon={<AppstoreOutlined />}>
            <Link to="/">Trang chủ</Link>
          </Menu.Item>
          ) : null
        }
        {
          StoreManager.userdata.jwtDecode?.role === "superadmin" ? (
            <Menu.Item key="2" icon={<ShopOutlined />}>
              <Link to="/companies">Quản lý công ty</Link>
            </Menu.Item>
          ) : null
        }
         {
          StoreManager.userdata.jwtDecode?.role != "user" ? (
            <Menu.Item key="3" icon={<UsergroupAddOutlined />}>
            <Link to="/users">Quản lý tài khoản</Link>
          </Menu.Item>
          ) : null
        }
        {
           StoreManager.userdata.jwtDecode?.role !== "superadmin" ?   <Menu.Item key="4" icon={<UsergroupAddOutlined />}>
           <Link to="/groups">Quản lý Group</Link>
         </Menu.Item> : null
        }
   
   {
           StoreManager.userdata.jwtDecode?.role !== "superadmin" ?      <Menu.Item key="5" icon={<TagsOutlined />}>
           <Link to="/tags">Quản lý thẻ</Link>
         </Menu.Item> : null
        }
      
     
        <Menu.Item key="6" icon={<BellOutlined />}>
          <Link to="/notifications">Thông báo</Link>
        </Menu.Item>
      </Menu>
      <Text style={{ margin: "20px 20px", display: collapsed ? "none" : "" }}>{version}</Text>
    </div>
  );
}

export default AppMenu;