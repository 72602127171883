import React from 'react';
import { Table, Button, Modal, Form, Input, Select, Popconfirm, Breadcrumb, message } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined, HomeOutlined, TagOutlined, EyeOutlined, PrinterOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import QRCode from 'react-qr-code'; // Import QR Code library
import { saveAs } from 'file-saver'; // Để tải file
import ExcelJS from 'exceljs'; // Thư viện ExcelJS
import QRCode2 from 'qrcode'; // Thư viện QR Code

import { getTags, createTag, updateTag, deleteTag, getGroups, getCompanies } from '../apis';
import StoreManager from '../appdata';

const { Option } = Select;

class TagManagement extends React.Component {
  state = {
    tags: [],
    groups: [],
    companies: [],
  };

  componentDidMount() {
    StoreManager.rootState.thisTagManagement = this;
    this.fetchTags();
    this.fetchGroups();
    this.fetchCompanies();
  }

  fetchTags = async () => {
    try {
      const tags = await getTags(); // Hàm getTags trả về data
      tags.reverse();
      this.setState({ tags });
    } catch (error) {
      console.error('Error fetching tags:', error.message); // Hiển thị lỗi
    }
  };

  fetchGroups = async () => {
    try {
      const groups = await getGroups();
      this.setState({ groups });
    } catch (error) {
      console.error('Error fetching groups:', error);
    }
  }

  fetchCompanies = async () => {
    try {
      const companies = await getCompanies();
      this.setState({ companies });
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  }

  showModal = (tag) => {
 
  };

  deleteTag = async (id) => {
    try {
      const message = await deleteTag(id); // Nhận thông báo từ API
      console.log(message);
      this.fetchTags();
    } catch (error) {
      console.error('Error deleting tag:', error.message);
    }
  };

  formRef = React.createRef();

  getGroupName = (groupId) => {
    const group = this.state.groups.find(group => group._id === groupId);
    return group ? group.group_name : 'Unknown';
  }

  getCompanyNameByGroupId = (groupId) => {
    const group = this.state.groups.find(group => group._id === groupId);
    if (!group) return 'Unknown';
    const company = this.state.companies.find(company => company._id === group.company_id);
    return company ? company.company_name : 'Unknown';
  }

  formatDateTime = (timestamp) => {
    if (timestamp === 0) return '0';
    const date = new Date(timestamp * 1000);
    const formattedTime = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
    const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
    return `${formattedTime}\n${formattedDate}`;
  }

  render() {
    const columns = [
      { title: 'Mã Thẻ', dataIndex: 'tag_id', key: 'tag_id', width: 70 },
      {
        title: 'QR Code',
        key: 'qr_code',
        width: 70,
        render: (text, record) => (
          <QRCode value={record.tag_id} size={64} /> // Display QR code
        ),
      },
      // { title: 'Loại Thẻ', dataIndex: 'tag_type', key: 'tag_type', width: 100 },
      { title: 'Tên Sản Phẩm', dataIndex: 'product_name', key: 'product_name', width: 100 },
      { title: 'Mô Tả', dataIndex: 'product_description', key: 'product_description', width: 100 },
      { title: 'Trạng Thái', dataIndex: 'status', key: 'status', width: 60 },
      // { title: 'Trạng Thái Hoạt Động', dataIndex: 'opStatus', key: 'opStatus', width: 100 },
      // { 
      //   title: 'Thời Gian Bắt Đầu', 
      //   dataIndex: 'startTime', 
      //   key: 'startTime', 
      //   width: 60,
      //   render: (text, record) => this.formatDateTime(record.startTime) 
      // },
      // { title: 'Tổng Số Lần', dataIndex: 'totalCount', key: 'totalCount', width: 100 },
      { title: 'Số Lần Ghi Nhận', dataIndex: 'recordedCount', key: 'recordedCount', width: 50 },
      // { title: 'Thời Gian Trễ (phút)', dataIndex: 'delayMinutes', key: 'delayMinutes', width: 100 },
      // { title: 'Khoảng Cách (giây)', dataIndex: 'intervalSeconds', key: 'intervalSeconds', width: 100 },
      // { title: 'Nhiệt Độ Thấp Nhất', dataIndex: 'recordedMinimum', key: 'recordedMinimum', width: 50 },
      // { title: 'Nhiệt Độ Cao Nhất', dataIndex: 'recordedMaximum', key: 'recordedMaximum', width: 50 },
      // { title: 'Lần Vượt Thấp', dataIndex: 'overLowCount', key: 'overLowCount', width: 100 },
      // { title: 'Lần Vượt Cao', dataIndex: 'overHighCount', key: 'overHighCount', width: 100 },
      // { title: 'Nhiệt Độ Hợp Lệ Tối Thiểu', dataIndex: 'validMinimum', key: 'validMinimum', width: 100 },
      // { title: 'Nhiệt Độ Hợp Lệ Tối Đa', dataIndex: 'validMaximum', key: 'validMaximum', width: 100 },
      // { 
      //   title: 'Nhóm | Công Ty', 
      //   key: 'group_id',
      //   width: 150,
      //   render: (text, record) => {
      //     const groupName = record.group_id ? this.getGroupName(record.group_id) : 'Không xác định';
      //     const companyName = record.group_id ? this.getCompanyNameByGroupId(record.group_id) : 'Không xác định';          
      //     return `${groupName} | ${companyName}`;
      //   }
      // },
      {
        title: 'Hành Động',
        key: 'action',
        width: 100,
        // fixed: 'right',
        render: (text, record) => (
          <span>
            <Button type="primary" ghost icon={<PrinterOutlined />} onClick={async() => {
               // Tạo workbook mới
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Tags');

        // Thêm tiêu đề
        worksheet.addRow(['Mã Thẻ', 'QR Code', 'Tên Sản Phẩm', 'Mô Tả']);

        // Tạo QR Code base64
        const qrCodeBase64 = await QRCode2.toDataURL(record.tag_id);

        // Thêm hàng dữ liệu
        const row = worksheet.addRow([
          record.tag_id,
          '', // Cột QR Code (sẽ chèn hình ảnh sau)
          record.product_name,
          record.product_description,
        ]);

        // Chèn hình ảnh QR Code
        const qrCodeImageId = workbook.addImage({
          base64: qrCodeBase64,
          extension: 'png',
        });

        worksheet.addImage(qrCodeImageId, {
          tl: { col: 1, row: row.number - 1 }, // Vị trí QR Code
          ext: { width: 80, height: 80 }, // Kích thước hình ảnh
        });

        // Lưu file Excel
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, `Tag_${record.tag_id}.xlsx`);


            }} style={{ marginRight: 10 }} />
            <Popconfirm
              title="Bạn có chắc chắn muốn xóa thẻ này?"
              onConfirm={() => this.deleteTag(record._id)}
              okText="Có"
              cancelText="Không"
            >
              <Button danger icon={<DeleteOutlined />} style={{ marginRight: 10 }} />
            </Popconfirm>
            <Link to={`/tags/${record._id}`}>
              <Button type="default" icon={<EyeOutlined />} />
            </Link>
          </span>
        ),
      },
    ];
    
    let scrollY = window.innerHeight - 300

    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 14 }}>
          <Breadcrumb
            items={[
              {
                href: '',
                title: <> <HomeOutlined />
                  <span>Home</span>
                </>,
              },
              {
                title: (
                  <>
                    <TagOutlined />
                    <span>Tags</span>
                  </>
                ),
              }
            ]}
          />
          <Button type="primary" icon={<PlusOutlined />} onClick={() => this.showModal(null)}>Add Tag</Button>
        </div>
        <Table 
          // scroll={{ y: scrollY, x: 2000 }}
          bordered 
          dataSource={this.state.tags} 
          columns={columns} 
          rowKey="_id" 
        />

    
      </div>
    );
  }
}

export default TagManagement;
