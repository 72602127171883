import React, { Component } from 'react';
import { List, Typography, Tag, message, Space, Button } from 'antd';
import dayjs from 'dayjs';
import { getNotifications, updateNotificationStatus } from '../apis';

class NotificationScreen extends Component {
  state = {
    notifications: [],
    loading: false,
  };

  componentDidMount() {
    this.fetchNotifications();
  }

  fetchNotifications = async () => {
    this.setState({ loading: true });
    try {
      const notifications = await getNotifications(); // Fetch notifications from API
      this.setState({ notifications: notifications.data || [], loading: false });
    } catch (error) {
      console.error('Error fetching notifications:', error.message);
      message.error('Không thể tải danh sách thông báo.');
      this.setState({ loading: false });
    }
  };

  markAsRead = async (notificationId) => {
    try {
      await updateNotificationStatus(notificationId); // Update the notification status
      message.success('Đánh dấu thông báo là đã đọc.');
      this.fetchNotifications(); // Refresh notifications list
    } catch (error) {
      console.error('Error updating notification status:', error.message);
      message.error('Không thể cập nhật trạng thái thông báo.');
    }
  };

  openLink = (link) => {
    if (link) {
      window.open(link, '_blank'); // Open the link in a new tab
    } else {
      message.warning('Không có liên kết cho thông báo này.');
    }
  };

  render() {
    const { notifications, loading } = this.state;

    return (
      <div style={{ padding: '20px', maxWidth: '800px', margin: 'auto' }}>
        <Typography.Title level={3}>Danh sách thông báo</Typography.Title>
        <List
          loading={loading}
          dataSource={notifications}
          renderItem={(item) => (
            <List.Item
              style={{
                background: item.status === 'READ' ? '#f6f9fc' : '#ffffff',
                border: '1px solid #e8e8e8',
                borderRadius: '8px',
                marginBottom: '10px',
                padding: '16px',
                cursor: item.link ? 'pointer' : 'default',
              }}
              onClick={() => this.openLink(item.link)}
              extra={
                <Button
                  type="link"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent triggering open link on click
                    this.markAsRead(item._id);
                  }}
                  disabled={item.status === 'READ'}
                >
                  {item.status === 'READ' ? 'Đã đọc' : 'Đánh dấu là đã đọc'}
                </Button>
              }
            >
              <List.Item.Meta
                title={
                  <Space>
                    <Typography.Text strong>{item.message}</Typography.Text>
                    {item.status === 'UNREAD' && <Tag color="blue">Chưa đọc</Tag>}
                  </Space>
                }
                description={dayjs(item.timestamp).format('HH:mm DD/MM/YYYY')}
              />
            </List.Item>
          )}
        />
      </div>
    );
  }
}

export default NotificationScreen;
