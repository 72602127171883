import React, { Component } from 'react';
import { Table, Descriptions, Breadcrumb, message } from 'antd';
import { HomeOutlined, TagOutlined } from '@ant-design/icons';
import { Line } from 'react-chartjs-2';
import dayjs from 'dayjs';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

import { getLogsByTagId, getTagById } from '../apis';

// Đăng ký các thành phần cần thiết của Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

class TagDetailScreen extends Component {
  state = {
    tag: {},
    logs: [],
  };

  componentDidMount() {
    const { tagId } = this.props.match.params;

    this.fetchTagDetails(tagId);
    this.fetchTagLogs(tagId);
  }

  fetchTagDetails = async (tagId) => {
    try {
      const tag = await getTagById(tagId); // Dữ liệu tag sẽ nằm trong `data`
      this.setState({ tag });
    } catch (error) {
      console.error('Error fetching tag details:', error.message); // Thêm thông báo lỗi
    }
  };

  fetchTagLogs = async (tagId) => {
    try {
      const fetchedLogs = await getLogsByTagId(tagId); // Dữ liệu logs sẽ nằm trong `data`
      const logsMap = new Map();
      fetchedLogs.forEach(log => {
        const key = new Date(log.op_time).toISOString();
        if (!logsMap.has(key)) {
          logsMap.set(key, { ...log, children: [] });
        } else {
          logsMap.get(key).children.push(log);
        }
      });
      const groupedLogs = Array.from(logsMap.values());
      this.setState({ logs: groupedLogs });
    } catch (error) {
      console.error('Error fetching logs:', error.message); // Thêm thông báo lỗi
    }
  };


  prepareChartData = () => {
    const { logs } = this.state;
    const labels = logs.map(log => new Date(log.op_time).toLocaleString());
    const data = logs.map(log => parseFloat(log.decimal_temp));

    return {
      labels,
      datasets: [
        {
          label: 'Nhiệt độ theo thời gian',
          data: data,
          borderColor: 'rgba(75,192,192,1)',
          backgroundColor: 'rgba(75,192,192,0.2)',
          fill: false,
        },
      ],
    };
  };

  render() {
    const { tag, logs } = this.state;

    const columns = [
      {
        title: 'Thời gian đọc',
        dataIndex: 'op_time',
        key: 'op_time',
        sorter: (a, b) => new Date(a.op_time) - new Date(b.op_time),
        render: (text) => (text ? dayjs(text).format('HH:mm DD/MM/YYYY') : 'N/A'),
      },
      { title: 'Temp ID', dataIndex: 'temp_id', key: 'temp_id' },
      // { title: 'Hex Temp', dataIndex: 'hex_temp', key: 'hex_temp' },
      { title: 'Nhiệt độ đọc', dataIndex: 'decimal_temp', key: 'decimal_temp' },
      // { title: 'Field Flag', dataIndex: 'field_flag', key: 'field_flag' },
      { title: 'Latitude', dataIndex: 'lat', key: 'lat' },
      { title: 'Longitude', dataIndex: 'long', key: 'long' },
    ];

    const maxHeight = window.innerHeight - 180;

    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 14 }}>
          <Breadcrumb
            items={[
              {
                href: '/',
                title: <> <HomeOutlined />
                  <span>Home</span>
                </>,
              },
              {
                href: '/tags',
                title: (
                  <>
                    <TagOutlined />
                    <span>Tags</span>
                  </>
                ),
              },
              {
                title: 'Tag Detail',
              }
            ]}
          />
        </div>
        <div
          style={{
            maxHeight: maxHeight,
            overflowY: 'auto',
            padding: 20,
            borderRadius: 8,
          }}
        >
          <Descriptions title="Thông tin thẻ" bordered>
            <Descriptions.Item label="Mã thẻ">{tag.tag_id}</Descriptions.Item>
            <Descriptions.Item label="Tên sản phẩm">{tag.product_name}</Descriptions.Item>
            <Descriptions.Item label="Mô tả sp">{tag.product_description}</Descriptions.Item>
            <Descriptions.Item label="Số lần đọc">{tag.recordedCount}</Descriptions.Item>
            <Descriptions.Item label="Nhiệt độ ngưỡng">{`${tag.validMinimum} - ${tag.validMaximum} (C)`}</Descriptions.Item>
            <Descriptions.Item label="Số lần vượt ngưỡng">
              <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                <span style={{
                  color: tag.overLowCount > 0 ? 'red' : 'green',
                  fontWeight: 'bold',
                }}>
                  {`Dưới: ${tag.overLowCount} lần`}
                </span>
                <span style={{
                  color: tag.overHighCount > 0 ? 'red' : 'green',
                  fontWeight: 'bold',
                }}>
                  {`Trên: ${tag.overHighCount} lần`}
                </span>
              </div>
            </Descriptions.Item>
            <Descriptions.Item label="Loại thẻ">{tag.tag_type}</Descriptions.Item>
            <Descriptions.Item label="Trạng thái">{tag.status}</Descriptions.Item>
            <Descriptions.Item label="Operation Status">{tag.opStatus}</Descriptions.Item>
            <Descriptions.Item label="Thời gian kích hoạt">
              {tag.startTime
                ? dayjs(tag.startTime * 1000).format('HH:mm DD/MM/YYYY')
                : 'N/A'}
            </Descriptions.Item>
          </Descriptions>


          <Table
            scroll={{ y: maxHeight - 300 }}
            style={{ marginTop: 20 }}
            bordered
            dataSource={logs}
            columns={columns}
            rowKey="_id"
            defaultSortOrder="descend"

            exexpandable={{
              expandedRowRender: record => (
                record.children && record.children.length > 0 ? (
                  <Table
                    columns={columns}
                    dataSource={record.children}
                    pagination={false}
                  />
                ) : null
              ),
              rowExpandable: record => record.children && record.children.length > 0,
            }}

          />

          <div style={{ marginTop: 20 }}>
            <h3>Biểu đồ nhiệt độ ghi nhận theo thời gian</h3>
            <Line data={this.prepareChartData()} />
          </div>
        </div>
      </div>
    );
  }
}

export default TagDetailScreen;
