import React from 'react';
import { Table, Button, Modal, Form, Input, Popconfirm, Breadcrumb, message } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined, HomeOutlined, ClusterOutlined } from '@ant-design/icons';
import { getCompanies, createCompany, updateCompany, deleteCompany } from '../apis';

import StoreManager from '../appdata';

class CompanyManagement extends React.Component {
  state = {
    companies: [],
    isModalVisible: false,
    editingCompany: null,
  };

  componentDidMount() {
    StoreManager.rootState.thisCompanyManagement = this;
    this.fetchCompanies();
  }

  fetchCompanies = async () => {
    try {
      const companies = await getCompanies(); // Gọi API mới
      companies.reverse();
      this.setState({ companies });
    } catch (error) {
      console.error('Error fetching companies:', error.message);
      message.error(`Error fetching companies: ${error.message}`);
    }
  };

  showModal = (company) => {
    this.setState({
      isModalVisible: true,
      editingCompany: company,
    });
  };

  handleOk = async () => {
    const { editingCompany } = this.state;
    const form = this.formRef.current;
    const values = await form.validateFields();
  
    try {
      if (editingCompany) {
        await updateCompany(editingCompany._id, values); // Gọi API mới
        message.success('Company updated successfully!');
      } else {
        await createCompany(values); // Gọi API mới
        message.success('Company created successfully!');
      }
      this.setState({ isModalVisible: false, editingCompany: null });
      this.fetchCompanies();
    } catch (error) {
      console.error('Error saving company:', error.message);
      message.error(`Error saving company: ${error.message}`);
    }
  };
  

  handleCancel = () => {
    this.setState({
      isModalVisible: false,
      editingCompany: null,
    });
  };

  deleteCompany = async (id) => {
    try {
      await deleteCompany(id); // Gọi API mới
      message.success('Company deleted successfully!');
      this.fetchCompanies();
    } catch (error) {
      console.error('Error deleting company:', error.message);
      message.error(`Error deleting company: ${error.message}`);
    }
  };
  

  formRef = React.createRef();

  render() {
    const columns = [
      { title: 'Name', dataIndex: 'company_name', key: 'company_name' },
      { title: 'Address', dataIndex: 'address', key: 'address' },
      { title: 'Phone', dataIndex: 'phone', key: 'phone' },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            <Button type="primary" ghost icon={<EditOutlined />} onClick={() => this.showModal(record)} style={{marginRight: 10}} />
            <Popconfirm
              title="Are you sure delete this company?"
              onConfirm={() => this.deleteCompany(record._id)}
              okText="Yes"
              cancelText="No"
            >
              <Button danger icon={<DeleteOutlined />} />
            </Popconfirm>
          </span>
        ),
      },
    ];
    let scrollY = window.innerHeight - 300
    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 14 }}>
          <Breadcrumb
          items={[
            {
              href: '',
              title: <> <HomeOutlined />
                <span>Home</span>
              </>,
            },
            {
              title: (
                <>
                  <ClusterOutlined />
                  <span>Companies</span>
                </>
              ),
            }
          ]}
        />
          <Button type="primary" icon={<PlusOutlined />} onClick={() => this.showModal(null)}>Add Company</Button>
        </div>
        <Table scroll={{ y: scrollY }} bordered dataSource={this.state.companies} columns={columns} rowKey="_id" />
        <Modal 
          title="Company" 
          visible={this.state.isModalVisible} 
          onOk={this.handleOk} 
          onCancel={this.handleCancel}
        >
          <Form 
            layout="vertical" 
            ref={this.formRef} 
            initialValues={this.state.editingCompany || { company_name: '', address: '', phone: '' }}
          >
            <Form.Item
              label="Name"
              name="company_name"
              rules={[{ required: true, message: 'Please input the company name!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Address"
              name="address"
              rules={[{ required: true, message: 'Please input the company address!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Phone"
              name="phone"
              rules={[{ required: true, message: 'Please input the company phone!' }]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default CompanyManagement;
