import StoreManager from "../appdata";
import { message } from 'antd';
import {jwtDecode} from "jwt-decode";

const API_URL = 'https://apitruonguy.demngayyeu.vn'; // Địa chỉ của server
// const API_URL = 'http://localhost:8090'; // Địa chỉ của server

export const loginAPI = (username, password) => {
  fetch(`${API_URL}/api/auth/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify({
      username: username,
      password: password,
    })
  })
  .then(response => response.json())
  .then(data => {
    if (data && data.token != null) {
      setTimeout(() => {
        StoreManager.userdata.token = data.token;
        StoreManager.userdata.jwtDecode = jwtDecode(data.token);
        console.log("StoreManager.userdata.jwtDecode", StoreManager.userdata.jwtDecode);
        StoreManager.updateDataToLocal();
        message.open({
          type: 'success',
          content: `Login Success`,
        });
        StoreManager.rootState.thisApp.setState({ isLogin: true });
      }, 1000);
    } else {
      message.open({
        type: 'error',
        content: `${data.message}`,
      });
      StoreManager.rootState.thisLogin.setState({ isLoading: false });
    }
  })
  .catch(error => {
    StoreManager.rootState.thisLogin.setState({ isLoading: false });
    console.error('Error:', error);
  });
}

// Function to get the authorization headers
const getAuthHeaders = () => ({
  'Content-Type': 'application/json',
  'Authorization': `Bearer ${StoreManager.userdata.token}`
});

export const getNotifications = async () => {
  try {
    const response = await fetch(`${API_URL}/api/notifications`, {
      method: 'GET',
      headers: getAuthHeaders(),
    });

    const data = await response.json();
    if (response.ok) {
      return data; // Trả về danh sách thông báo
    } else {
      throw new Error(data.message || 'Failed to fetch notifications');
    }
  } catch (error) {
    console.error('Error fetching notifications:', error.message);
    throw error;
  }
};

export const updateNotificationStatus = async (id, status) => {
  try {
    const response = await fetch(`${API_URL}/api/notifications/${id}`, {
      method: 'PUT',
      headers: {
        ...getAuthHeaders(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ status }),
    });

    const data = await response.json();
    if (response.ok) {
      return data.data; // Trả về thông báo đã cập nhật
    } else {
      throw new Error(data.message || 'Failed to update notification status');
    }
  } catch (error) {
    console.error('Error updating notification status:', error.message);
    throw error;
  }
};


// Companies API
export const getCompanies = async () => {
  const response = await fetch(`${API_URL}/api/companies`, {
    method: 'GET',
    headers: getAuthHeaders(),
  });
  const result = await response.json();
  if (result.code === 200) {
    return result.data; // Trả về danh sách các công ty
  } else {
    throw new Error(result.message); // Ném lỗi nếu không thành công
  }
};

export const createCompany = async (company) => {
  const response = await fetch(`${API_URL}/api/companies`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify(company),
  });
  const result = await response.json();
  if (result.code === 200) {
    return result.data; // Trả về công ty vừa được tạo
  } else {
    throw new Error(result.message);
  }
};

export const updateCompany = async (id, company) => {
  const response = await fetch(`${API_URL}/api/companies/${id}`, {
    method: 'PUT',
    headers: getAuthHeaders(),
    body: JSON.stringify(company),
  });
  const result = await response.json();
  if (result.code === 200) {
    return result.data; // Trả về công ty sau khi cập nhật
  } else {
    throw new Error(result.message);
  }
};


export const deleteCompany = async (id) => {
  const response = await fetch(`${API_URL}/api/companies/${id}`, {
    method: 'DELETE',
    headers: getAuthHeaders(),
  });
  const result = await response.json();
  if (result.code === 200) {
    return result.data; // Trả về thông báo hoặc dữ liệu liên quan
  } else {
    throw new Error(result.message);
  }
};


// TagGroups API
export const getTagGroups = () => {
  return fetch(`${API_URL}/api/tagGroups`, {
    method: 'GET',
    headers: getAuthHeaders()
  }).then(response => response.json());
}

export const createTagGroup = (tagGroup) => {
  return fetch(`${API_URL}/api/tagGroups`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify(tagGroup)
  }).then(response => response.json());
}

export const updateTagGroup = (id, tagGroup) => {
  return fetch(`${API_URL}/api/tagGroups/${id}`, {
    method: 'PUT',
    headers: getAuthHeaders(),
    body: JSON.stringify(tagGroup)
  }).then(response => response.json());
}

export const deleteTagGroup = (id) => {
  return fetch(`${API_URL}/api/tagGroups/${id}`, {
    method: 'DELETE',
    headers: getAuthHeaders()
  }).then(response => response.json());
}

// Tags API
// Ví dụ cập nhật hàm getTags
export const getTags = () => {
  return fetch(`${API_URL}/api/tags`, {
    method: 'GET',
    headers: getAuthHeaders(),
  })
    .then(response => response.json())
    .then(data => {
      if (data.code === 200) {
        return data.data; // Lấy danh sách tags từ data
      } else {
        throw new Error(data.message); // Ném lỗi nếu không thành công
      }
    });
};



export const createTag = (tag) => {
  return fetch(`${API_URL}/api/tags`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify(tag),
  })
    .then(response => response.json())
    .then(data => {
      if (data.code === 200) {
        return data.data; // Trả về tag đã tạo
      } else {
        throw new Error(data.message); // Thông báo lỗi nếu có
      }
    });
};


export const updateTag = (id, tag) => {
  return fetch(`${API_URL}/api/tags/${id}`, {
    method: 'PUT',
    headers: getAuthHeaders(),
    body: JSON.stringify(tag)
  }).then(response => response.json());
}

export const deleteTag = (id) => {
  return fetch(`${API_URL}/api/tags/${id}`, {
    method: 'DELETE',
    headers: getAuthHeaders(),
  })
    .then(response => response.json())
    .then(data => {
      if (data.code === 200) {
        return data.message; // Trả về thông báo xóa thành công
      } else {
        throw new Error(data.message);
      }
    });
};


export const getTagById = (tagId) => {
  return fetch(`${API_URL}/api/tags/${tagId}`, {
    method: 'GET',
    headers: getAuthHeaders(),
  })
    .then(response => response.json())
    .then(data => {
      if (data.code === 200) {
        return data.data; // Trả về dữ liệu tag
      } else {
        throw new Error(data.message); // Thông báo lỗi nếu có
      }
    });
};



// Logs API
export const getLogsByTagId = (tag_id) => {
  return fetch(`${API_URL}/api/logs/tag/${tag_id}`, {
    method: 'GET',
    headers: getAuthHeaders(),
  })
    .then(response => response.json())
    .then(data => {
      if (data.code === 200) {
        return data.data; // Trả về dữ liệu logs
      } else {
        throw new Error(data.message); // Thông báo lỗi nếu có
      }
    });
};


export const getLogs = () => {
  return fetch(`${API_URL}/api/logs`, {
    method: 'GET',
    headers: getAuthHeaders()
  }).then(response => response.json());
}

export const createLog = (log) => {
  return fetch(`${API_URL}/api/logs`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify(log)
  }).then(response => response.json());
}

export const updateLog = (id, log) => {
  return fetch(`${API_URL}/api/logs/${id}`, {
    method: 'PUT',
    headers: getAuthHeaders(),
    body: JSON.stringify(log)
  }).then(response => response.json());
}

export const deleteLog = (id) => {
  return fetch(`${API_URL}/api/logs/${id}`, {
    method: 'DELETE',
    headers: getAuthHeaders()
  }).then(response => response.json());
}

// Users API
export const getUsers = async () => {
  const response = await fetch(`${API_URL}/api/users`, {
    method: 'GET',
    headers: getAuthHeaders(),
  });
  const result = await response.json();
  if (result.code === 200) {
    return result.data; // Trả về danh sách người dùng
  } else {
    throw new Error(result.message); // Ném lỗi nếu không thành công
  }
};


export const createUser = async (user) => {
  const response = await fetch(`${API_URL}/api/users`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify(user),
  });
  const result = await response.json();
  if (result.code === 200) {
    return result.data; // Trả về thông tin người dùng mới
  } else {
    throw new Error(result.message);
  }
};


export const updateUser = async (id, user) => {
  const response = await fetch(`${API_URL}/api/users/${id}`, {
    method: 'PUT',
    headers: getAuthHeaders(),
    body: JSON.stringify(user),
  });
  const result = await response.json();
  if (result.code === 200) {
    return result.data; // Trả về thông tin người dùng đã cập nhật
  } else {
    throw new Error(result.message);
  }
};

export const deleteUser = async (id) => {
  const response = await fetch(`${API_URL}/api/users/${id}`, {
    method: 'DELETE',
    headers: getAuthHeaders(),
  });
  const result = await response.json();
  if (result.code === 200) {
    return result.data; // Trả về thông báo thành công
  } else {
    throw new Error(result.message);
  }
};


// ... các hàm khác

// API to reset user password
export const resetUserPassword = async (id) => {
  const response = await fetch(`${API_URL}/api/users/${id}/reset-password`, {
    method: 'POST',
    headers: getAuthHeaders(),
  });
  const result = await response.json();
  if (result.code === 200) {
    return result.message; // Trả về thông báo thành công
  } else {
    throw new Error(result.message);
  }
};

// API to get groups
export const getGroups = async () => {
  const response = await fetch(`${API_URL}/api/tagGroups`, {
    method: 'GET',
    headers: getAuthHeaders(),
  });
  const result = await response.json();
  if (result.code === 200) {
    return result.data; // Trả về danh sách groups
  } else {
    throw new Error(result.message); // Ném lỗi nếu có
  }
};
// API to create group
export const createGroup = async (group) => {
  const response = await fetch(`${API_URL}/api/tagGroups`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify(group),
  });
  const result = await response.json();
  if (result.code === 200) {
    return result.data; // Trả về group vừa tạo
  } else {
    throw new Error(result.message);
  }
};

// API to update group
export const updateGroup = async (id, group) => {
  const response = await fetch(`${API_URL}/api/tagGroups/${id}`, {
    method: 'PUT',
    headers: getAuthHeaders(),
    body: JSON.stringify(group),
  });
  const result = await response.json();
  if (result.code === 200) {
    return result.data; // Trả về group đã cập nhật
  } else {
    throw new Error(result.message);
  }
};

// API to delete group
export const deleteGroup = async (id) => {
  const response = await fetch(`${API_URL}/api/tagGroups/${id}`, {
    method: 'DELETE',
    headers: getAuthHeaders(),
  });
  const result = await response.json();
  if (result.code === 200) {
    return result.data; // Trả về kết quả xóa
  } else {
    throw new Error(result.message);
  }
};